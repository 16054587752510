/* Subtle Fade In Animation */
@keyframes subtleFadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .fade-in {
    animation: subtleFadeIn 0.4s ease-in-out;
  }
  
  /* Subtle Fade In from Bottom */
  @keyframes subtleFadeInFromBottom {
    from { 
      opacity: 0;
      transform: translateY(10px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-bottom {
    animation: subtleFadeInFromBottom 0.8s ease-in-out;
  }
  
  /* Subtle Fade In for Lists */
  .fade-in-list > * {
    opacity: 0;
    animation: subtleFadeIn 0.2s ease-in-out forwards;
  }
  
  .fade-in-list > *:nth-child(1) { animation-delay: 0.1s; }
  .fade-in-list > *:nth-child(2) { animation-delay: 0.1s; }
  .fade-in-list > *:nth-child(3) { animation-delay: 0.1s; }
  .fade-in-list > *:nth-child(4) { animation-delay: 0.1s; }
  .fade-in-list > *:nth-child(5) { animation-delay: 0.1s; }
  /* Add more if needed */
  
  /* Subtle Hover Fade */
  .hover-fade {
    transition: opacity 0.3s ease-in-out;
  }
  
  .hover-fade:hover {
    opacity: 0.8;
  }

  /* ... (existing animations remain the same) */

/* Subtle Fade Out Animation */
@keyframes subtleFadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  
  .fade-out {
    animation: subtleFadeOut 0.5s ease-in-out forwards;
  }
  
  /* Subtle Fade Out to Bottom */
  @keyframes subtleFadeOutToBottom {
    from { 
      opacity: 1;
      transform: translateY(0);
    }
    to { 
      opacity: 0;
      transform: translateY(10px);
    }
  }
  
  .fade-out-bottom {
    animation: subtleFadeOutToBottom 0.5s ease-in-out forwards;
  }
  
  /* ... (rest of the existing animations remain the same) */