.card {
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card.active {
    border-color: #2c4c3b;
    color: #2c4c3b;
  }
  
  .card-header {
    margin-bottom: 1rem;
  }
  
  .card-title {
    font-size: 1.25rem;
  }
  
  .card-content {
    margin-bottom: 1rem;
  }
  
  .card-description {
    font-size: 1rem;
  }
  .ingredient-tag {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-family: "Inconsolata", monospace;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #ffdddd;
    border: #2f4a3c 1px solid;
    border-radius: 3px;
    padding: 3px;
    margin-right: 5px;
    color: #2f4a3c;
  }
  