.header {
  background-color: #2F4A3C;
  padding: 15px 0;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-nav {
  position: absolute;
  left: 30px;
}

.back-to-main {
  color: #FFFFFF;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.back-to-main svg {
  margin-right: 8px;
}

.header-title {
  width: 100%;
  text-align: center;
}

#site-title {
  color: #FFFFFF;
  font-family: "new-spirit", serif;
  font-weight: 300;
  font-size: 21px;
  text-decoration: none;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .header-nav {
    position: static;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .back-to-main {
    justify-content: center;
  }

  .header-title {
    width: 100%;
    text-align: center;
  }
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}