/* General styles and imports */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mate:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Work Sans", sans-serif; 
  background-color: white;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, .font-title {
  font-family: new-spirit, serif;
font-weight: 400;
}
p {
  color: #2f4a3c;
}
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.grid {
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  
}
.card.blend-support-card.cursor-pointer {
  width: 46%;
}

.accordion-card {
  width: 46%;
}

.card.accordion-card.cursor-pointer.transition-all.hover\:shadow-lg.active {
  width: 45%;
  background-color: #ffeeed;
 
}
.card.cursor-pointer.transition-all.hover\:shadow-lg {
  background-color: #f8f8f8;
}

.create-blend-card {
  width: 45%;
  min-height: 100px;
}
.active-ingredients {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  column-gap: 1rem;
  
}
@media (max-width: 1120px) {
  .grid {
    flex-wrap: wrap;
  }
  .card.blend-support-card.cursor-pointer {
    width: -webkit-fill-available;
  }
  .accordion-card {
    width: auto;
  }
  .card.accordion-card.cursor-pointer.transition-all.hover\:shadow-lg.active {
    width: auto;
  }
  .create-blend-card {
    width: auto;
  }
  .active-ingredients {
    display: grid;}
}

.card {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  background-color: #F9D8D6;
  position: relative; /* For label positioning */
  margin-top: 1rem;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card.active {
  border-color: #c8cbc9;
  background-color: #30493C;
 color: #2c4c3b;
}

.card.active .card-title, .card.active .card-content, .card.active p {
  color: #2c4c3b;
}

.card-header {
  margin-bottom: 1rem;
  display: flex;
}

.card-title {
  font-size: auto;
  font-family: new-spirit, serif;
  color: #30493C;
  align-content: center;
  column-gap: 2%;
}

.card-content {
  margin-bottom: auto;
}

.card-description {
  font-size: 1rem;
  
}

.signature-blends {
  margin-bottom: 2rem;
}

.ingredients-section {
  padding: 1rem;
}


.main-content {
  display: flex;
  flex-direction: column;
}

.content-section {
  flex: 1;
}

.button-container {
  margin-top: 2rem;
}

.popup-message {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffdddd;
  color: #d8000c;
  padding: 1rem;
  border: 1px solid #d8000c;
  border-radius: 8px;
  z-index: 1000;
}

.bg-primary {
  background-color: #F9D8D6;
}

.bg-secondary {
  background-color: #30493C;
}

.text-secondary {
  color: #30493C;
}



/* Form and Button Styling */
input[type="text"],
input[type="email"],
input[type="number"],
select {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
}

button,
input[type="submit"] {
  background-color: #30493C;
  color: white;
  padding: 15px 15px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Inconsolata", monospace;
}

button:hover,
input[type="submit"]:hover {
  background-color: #263a2e;
}

h2.text-4xl.font-title.mb-6.text-secondary {
  font-size: 2rem;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .signature-blends .grid {
    grid-template-columns: repeat(2, 1fr);
  }

 

  .summary-section {
    width: 100%;
    margin-left: 0;
  }

  .summary-container {
    position: static;
  }
}

@media (min-width: 769px) {
  .signature-blends .grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .ingredients-section .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1025px) {
  .signature-blends .grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .ingredients-section .grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .container {
    display: flexbox;
    justify-content: space-between;
  }

  .main-content {
    display: flex;
    flex-wrap: wrap;
  }

  .content-section {
    flex: 2;
    width: 100%;
  }

  .summary-section {
    width: 100%;
    margin-left: 0;
    flex: 1;
  }

  .summary-container {
    margin-left: 1rem;
  }

  .h2 {
    font-family: "Mate", serif;
    font-size: 3rem;
  }
}

/* New styles for the 'Your Tea' section */
.your-tea {
  margin-bottom: 2rem;
}

.your-tea .summary-container {
  background-color: white;
}

.your-tea .active-ingredients {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 3 cards per row on desktop */
  gap: 1rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .your-tea .active-ingredients {
    grid-template-columns: 1fr 1fr; /* 2 cards per row on mobile */
  }
}

/* Accordion styles */
.accordion {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.accordion-header {
  background-color: #F9D8D6;
  padding: 1.2rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inconsolata", monospace;
  font-weight: 700;
  color: #30493C;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-content.open {
  max-height: 5000px;
  transition: max-height 0.3s ease;
  padding: 0rem 1rem 1rem;
}

/* Labels for adding and removing ingredients */
.label {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  border: #c8cbc9 1px solid;
  font-size: 0.875rem;
  font-weight: bold;
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #e16060;
  font-family: "Inconsolata", monospace;

}

.card.remove .label {
  background-color: #be6464;
}
.label.add {
  background-color: #30493C; /* Green for add */
}

.your-tea .active-ingredients {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 3 cards per row on desktop */
  gap: 1rem;
  margin-top: 1rem;
  padding-bottom: 1rem; /* Padding at the bottom to avoid touching */
}

@media (max-width: 768px) {
  .your-tea .active-ingredients {
    grid-template-columns: 1fr 1fr; /* 2 cards per row on mobile */
    gap: 1rem 1rem;
  }
}
.your-tea {
  margin-bottom: 2rem;
}

.your-tea .summary-container {
  background-color: white;
}

.your-tea .active-ingredients {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2 cards per row */
  gap: 1rem;
  margin-top: 1rem;
}

.back-button {
  display: flex;
  align-items: center;
  font-family: "Inconsolata", monospace;
  font-size: 1rem;
  color: #30493C;
  text-decoration: none;
  background-color: #F9D8D6;
}

button:hover, input[type="submit"]:hover {
  background-color: #eccfcf;
}
.back-button svg {
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
}
.step{
margin-top: 2rem;}

.responsive-button {
  width: 100%;
  display: block;
  padding: 1.2rem;
  font-size: 1em;   
  margin: 4px;
}

@media (min-width: 768px) {
  .responsive-button {
    width: auto;
    display: inline-block;
  }
}

.intro-section {
  background-color: #f9d9d5;
  margin-bottom: 2rem;
  border-radius: 8px;
  font-family: "new-spirit", serif;
  font-weight: 300;
  font-size: 1.2rem;
  padding-left: 2rem;
  color: #263a2e;
}

.intro-content {
  display: flex;
  flex-direction: column;
  align-items: center;
    color: #263a2e;
}

.intro-text {
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Work Sans", sans-serif;
  color: #263a2e;
  padding-right: 2rem;

  
}

.intro-title {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-family: "new-spirit", serif;
  font-weight: 300;
  color: #263a2e;

}

.intro-image {
  max-width: 200px;
  height: auto;
  border-radius: 30px;
}

@media (min-width: 768px) {
  .intro-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .intro-text {
    text-align: left;
    margin-bottom: 0;
    flex: 1;
    padding-right: 2rem;
  }

  .intro-title {
    font-size: 2.5rem;
  }

  .intro-image {
    max-width: 300px;
  }
}
.card.cursor-pointer.start-from-scratch {
  background: #f7f7f7;
  border: 1px dashed;
  border: dashed 2px #2f4a3c;
}

.card.cursor-pointer.add-ingredients {
  background: #f7f7f7;
  border: 1px dashed;
  border: dashed 2px #2f4a3c;
}

.cursor-pointer.active-2 {
  background-color: #2c4c3b;
}

.cursor-pointer.active-2 .card-content .text-secondary {
    color: #ffffff;
}
.cursor-pointer.active-2 .material-symbols-outlined {
  color: #ffffff;
}

.cursor-pointer.active-2 .card-header .card-title {
  color: #ffffff;
}

.cart-button {
  background-color: #F9D8D6;
  color: #30493C;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Inconsolata", monospace;
}

.cart-button:hover {
  background-color: #eccfcf;
}
.cart-modal {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.cart-content {
  transition: transform 0.3s ease-in-out;
  transform: scale(0.9);
}

.cart-modal.open .cart-content {
  transform: scale(1);
}
.bg-smoke-light {
  background-color: rgba(0, 0, 0, 0.4);
}

.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.z-50 {
  z-index: 50;
}

.overflow-auto {
  overflow: auto;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.items-center {
  align-items: baseline;
  margin-top: 1.5rem;
}

.justify-center {
  justify-content: center;
}

.p-4 {
  padding: 1rem;
}

.m-auto {
  margin: auto;
}

.max-w-md {
  max-width: 28rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.bg-white {
  background-color: #ffffff;
}

.text-secondary {
  color: #30493C;
}

.hover\:bg-opacity-90:hover {
  opacity: 0.9;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.duration-150 {
  transition-duration: 150ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.max-h-96 {
  max-height: 24rem;
}

.overflow-y-auto {
  overflow-y: auto;
}
.rounded-full {
  border-radius: 9999px;
}

.top-2 {
  top: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.hover\:bg-gray-100:hover {
  background-color: #f3f4f6;
}

.h-6 {
  height: 1.5rem;
}

.w-6 {
  width: 1.5rem;
}
.cart-close-button {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  margin-top: 20px;
  margin-left: 20px;
  background-color: #ffdddd;
}

.cart-close-button:hover {
  background-color: #e2bcbc;  /* Darker pink on hover */
}
.bg-white.w-full.max-w-md.m-auto.flex.flex-col.rounded-lg.shadow-lg.overflow-hidden {
  flex-flow: column;
}

.flex-1.p-6.overflow-y-auto.max-h-\[calc\(80vh-2\.5rem\)\] {
  margin-left: 20px;
  margin-bottom: 20px;
}


button.bg-secondary.text-white.hover\:bg-opacity-90.px-3.py-1.rounded-l-md {
  background:none;
  color: #263a2e;
  font-weight: 800;
}

button.bg-secondary.text-white.hover\:bg-opacity-90.px-3.py-1.rounded-r-md {
  background:none;
  color:#263a2e;
  font-weight: 800;

}
button.bg-red-500.text-white.hover\:bg-red-600.px-3.py-1.rounded-md.ml-2 {
  background:none;
  color:red;
  font-weight: 800;
}
span.px-3.py-1.bg-white.border.border-secondary.rounded-md.min-w-\[40px\].text-center {
  padding-left: 20px;
  padding-right: 20px;
}
.flex.flex-col.sm\:flex-row.justify-between.items-start.sm\:items-center.mb-2 {
  display: flow;
}
p.mb-4.text-secondary.font-bold {
  font-weight: 600;
}

/*From GPT Septermber 17s */


.cart-item {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.cart-item .flex {
  justify-content: flex-start;
  align-items: normal;
}



/* Updated Order Total Box */
.order-total-card {
  position: sticky;
  bottom: 20px;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  max-width: 600px;
  border-radius: 12px;
  margin-top: 2rem;
  padding: 1.5rem;
}

.order-total-card .space-y-4 > * + * {
  margin-top: 1rem;
}

.order-total-card .checkout-button {
  margin-top: 1.5rem !important;
}

@media (max-width: 768px) {
  .order-total-card {
    border-radius: 12px 12px 0 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    margin: 0;
    padding: 1.5rem 2rem;
    padding-bottom: calc(1.5rem + env(safe-area-inset-bottom));
  }
}

.sticky-button {
  position: sticky;
  bottom: 0;
  background-color: #30493C;
  color: #ffffff;
  width: 100%;
  padding: 1rem;
  text-align: center;
  border-radius: 8px;
  margin-top: 1rem;
}

/* Quantity Control Styling */
/* Quantity Control Styling */
.quantity-control-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem; /* Add space between elements */
  margin-right: 1rem;
}

.quantity-button {
  background-color: #e5e7eb;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.quantity-button:hover {
  background-color: #d1d5db;
}

.quantity-display {
  padding: 0.5rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  min-width: 50px; /* Increase min width for better visibility */
  text-align: center;
  font-size: 1rem;
}

.remove-button {
  background-color: #ef4444;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.remove-button:hover {
  background-color: #dc2626;
}

/* Checkout Button Styling */
.checkout-button {
  background-color: #30493C;
  font-family: "Inconsolata", monospace;
  color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  text-align: center;
  width: auto; /* Default width for larger screens */
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #263a2e;
}

/* Make Checkout Button Full Width on Mobile */
@media (max-width: 768px) {
  .checkout-button {
    width: 100%; /* Full width on mobile */
    display: block;
  }
  .mt-4.mb-8.flex.justify-between.items-center {
    display: flex;
    flex-wrap: wrap;
}

.items-center{
  display: flex;
  flex-wrap: wrap;
}
}
.mt-4.p-4.border-t {
  background: #faeded;
  border-radius: 5px;
  border: #c8cbc9 1px solid;
  margin-top: 1rem;
}

h3.text-2xl.font-title.mb-4.text-secondary{
  margin-top: 0;
}

h2.text-3xl.font-title.mb-4.text-secondary {
  margin-top: 0
};


@media (max-width: 768px) {
  .grid {
    display:flex;
    flex-wrap: wrap;
  }
 
}

/* Existing styles... */

/* Existing styles... */

/* Updated Footer styles */
footer {
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
background-color: #f9d9d5;
padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 10rem;
}

footer h3 {
  font-family: "new-spirit", serif;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 1rem;
}

footer a {
  color: #30493C;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #1a2b22;
}

@media (max-width: 768px) {
  footer .container > div {
    grid-template-columns: 1fr;
  }
  footer {
    margin-top: -8rem;

  }
}


ul.space-y-2 {
  padding-left: 0;
  list-style: none;
}

.text-center{
  width: 33%;
  text-align: center;
}

@media (max-width: 768px) {
  .text-center{
    width: 100%;
   }
 
}


span.quantity-display.bg-white.border.border-gray-300.rounded-md.px-3.py-1.text-center.min-w-\[40px\] {
  width: 5%;
}

.back-to-top-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #30493C;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  z-index: 9999;
  border: none;
  cursor: pointer;
  display: none;
}

.back-to-top-button:hover {
  background-color: #263a2e;
}

@media (max-width: 640px) {
  .back-to-top-button {
    bottom: 10px;
    left: 10px;
    font-size: 14px;
    padding: 8px 12px;
  }
}

/* ... (existing styles remain the same) */

.desktop-button-container {
  display: none;
}

.sticky-buttons-container {
  display: none;
}

@media (min-width: 769px) {
  .desktop-button-container {
    display: block;
    margin-top: 1rem;
  }}

@media (max-width: 768px) {

  .desktop-button-container {
    display: none;
  }

  .sticky-buttons-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    z-index: 1000;
  }
  
  /* Sticky buttons */
  .sticky-button {
    flex: 1;
    margin: 0 5px;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 30px;
    border: #c8cbc9 1px solid;
  }
  
  .add-to-cart-button {
    background-color: #30493C;
    color: white;
  }
  
  .view-order-button {
    background-color: #f9d9d5;
    color: #30493C;
  }
  
  /* Media queries */
  @media (min-width: 769px) {
    .sticky-buttons-container {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .desktop-button-container {
      display: none;
    }
    
    .sticky-buttons-container {
      flex-direction: row;
    }
    
    .sticky-button {
      width: calc(50% - 10px);
    }
  }
  
  /* When there's only one button, make it full width */
  .sticky-buttons-container:only-child .sticky-button {
    width: 100%;
  }

  /* Add some bottom padding to the main content to prevent overlap with sticky buttons */
  .step {
    padding-bottom: 120px; /* Adjust this value based on the height of your sticky buttons */
  }

  .mt-4.mb-8.flex.justify-between.items-center {
    display: none;
    
}
button.bg-primary.text-secondary.hover\:bg-opacity-90.responsive-button.opacity-50.cursor-not-allowed {
  border: black 1px solid;
}
}

.add-another-blend-card {
  background-color: #f7f7f7;
  border: 2px dashed #2f4a3c;
  transition: all 0.3s ease;
}

.add-another-blend-card:hover {
  background-color: #e6e6e6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pregnancy-info {
  display: flex;
  flex-direction: row;
  align-items: center;
   font-size: 0.85rem;
  color: #555;
  border: #263a2e 1px solid;
  padding: 8px;
  border-radius: 4px;
  margin-top: 12px;
}

.pregnancy-icon {
  margin-bottom: 6px;
}

.pregnancy-icon svg {
  width: 32px;
  height: 32px;
  color: #30493C;
}

.pregnancy-text {
  text-align: left;
  margin: 0;
  line-height: 1.2;
  padding-left: 1rem;
}

.pregnancy-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pregnancy-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 90%;
  width: 400px;
  margin: 2rem;
}

.pregnancy-popup h2 {
  margin-bottom: 15px;
  color: #30493C;
}

.pregnancy-popup p {
  margin-bottom: 20px;
}

.pregnancy-popup-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pregnancy-popup-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.pregnancy-popup-buttons button:first-child {
  background-color: #F9D8D6;
  color: #30493C;
}

.pregnancy-popup-buttons button:last-child {
  background-color: #30493C;
  color: white;
}

.pregnancy-popup-buttons button:hover {
  opacity: 0.9;
}

/* Pregnancy Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 2%;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #30493C;
}

input:focus + .slider {
  box-shadow: 0 0 1px #30493C;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pregnancy-toggle {
  margin-left: auto;
  align-items: center;
}

.quantity-control-container.flex.items-center.space-x-2.fade-in {
  flex-wrap: nowrap;
}


button.bg-red-500.remove-button.tsremove {
  display: none;
}

.textspan{
  width: 100%;
}

/* ... (existing styles remain the same) */

/* ... (existing styles remain the same) */

/* Styles for the sticky ingredients */
.sticky-ingredients {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #f9d9d5;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.sticky-ingredients.visible {
  opacity: 1;
  pointer-events: auto;
}

.sticky-ingredients p {
  margin: 0;
  font-family: "Inconsolata", monospace;
  color: #30493C;
  font-weight: bold;
}

/* Mobile styles */
@media (max-width: 768px) {
  .sticky-ingredients {
    top: 0;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .sticky-ingredients {
    top: 0;
  }
}

/* ... (rest of the existing styles) */

/* Button styles for desktop */
@media (min-width: 768px) {
  .add-to-cart-button {
    background-color: #30493C !important;
    color: white !important;
  }

  .view-order-button {
    background-color: #F9D8D6 !important;
    color: #30493C !important;
  }

  .add-to-cart-button:hover {
    opacity: 0.9;
  }

  .view-order-button:hover {
    background-color: #eccfcf !important;
  }
}

/* Desktop layout for Step 3 */
.desktop-layout {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (min-width: 1024px) {
  .desktop-layout {
    display: grid;
    grid-template-columns: 1fr 350px;
    gap: 2rem;
    align-items: start;
  }

  .main-content {
    width: 100%;
  }

  .order-total-card {
    position: sticky;
    top: 20px;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    height: fit-content;
  }
}

/* Mobile layout for Step 3 */
@media (max-width: 1023px) {
  .desktop-layout {
    display: block;
  }

  .order-total-card {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    padding-bottom: calc(1.5rem + env(safe-area-inset-bottom));
    z-index: 10;
  }
}